<template>
  <div class="page_container">
    <div class="img_box">
      <img :src="imageUrl"/>
    </div>
    <ul class="list_spe">
      <li class="list_item" v-for="(item, index) in specialList" :key="index" @click="skipDetailList(item)">
        <div class="img_inner_box">
          <img :src="item.coverUrl"/>
        </div>
        <div class="title">{{ item.title }}</div>
        <div class="description">{{ item.introduce }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "index",
  data: () => ({
    specialList: [],
    imageUrl: ''
  }),
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.axios.get('/index/special').then(res => {
        this.specialList = res.list
        this.imageUrl = res.imageUrl
      })
    },
    skipDetailList(special) {

      this.$router.push({name: 'SpecialList', params: {spId: special.spId}})
    }
  }
}
</script>

<style lang="scss" scoped>
.page_container {
  background: #FFFFFF;
  border-radius: 4px;
  padding: 20px 40px;
  margin-bottom: 40px;

  .img_box {
    width: 100%;
    height: 340px;
    padding-bottom: 40px;
    border-bottom: 1px solid #E4E4E4;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  .list_spe {
    padding-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .list_item {
      width: 474px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      margin-bottom: 30px;
      cursor: pointer;
      position: relative;

      //&:nth-child(odd) {
      //  margin-right: 40px;
      //}
      //&:last-child:nth-child(odd) {
      //  margin-right: calc(51%);
      //}

      .img_inner_box {
        img {
          width: 100%;
          height: 300px;
          object-fit: cover;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          overflow: hidden;
          font-size: 0;
          box-sizing: border-box;
        }
      }

      .title {
        margin-top: 10px;
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        padding: 0 15px;
        letter-spacing: 1px;
        line-height: 1.2rem;

      }

      .description {
        font-size: 14px;
        color: #666666;
        padding: 10px 15px;
        letter-spacing: 1px;
        line-height: 1.2rem;
        word-wrap:break-word;
      }
    }


    .list_item:hover {
      bottom: 3px;
      box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
    }


  }
}
</style>
